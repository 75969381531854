require("./bootstrap");

$(function () {
    $("#loading-spinner").hide();

    // Handler for .ready() called.

    // This is a functions that scrolls to #{blah}link
    function goToByScroll(id) {
        // Remove "link" from the ID
        id = id.replace("link", "");
        // Scroll
        $("html,body").animate(
            {
                scrollTop: $("#" + id).offset().top - 57,
            },
            "slow"
        );
    }
    // /
    $("a.nav-link, li.nav-link > a, .navbar-brand").on("click", function (e) {
        // Prevent a page reload when a link is pressed
        e.preventDefault();

        if (
            $(this).data("to") == undefined ||
            window.location.pathname != "/"
        ) {
            window.location = $(this).attr("href");
            return;
        }
        // Call the scroll function
        goToByScroll($(this).data("to"));

        $("a, .nav-item").removeClass("active");

        if ($(this).hasClass("nav-link")) {
            $(this).parent().addClass("active");
        } else {
            $(this).addClass("active");
        }
    });

    $(".nav-link").on("click", function () {
        if (!$("nav > button").is(":visible")) {
            return;
        }
        if (
            !$("nav > button").hasClass("collapsed") &&
            !$(this).hasClass("dropdown-toggle")
        ) {
            $("nav > button").trigger("click");
        }
    });

    $("#client-login-button").on("click", function () {
        $("#loading-spinner").show();
        $("#login-error-message").html(``);
        setTimeout(function () {
            $("#loading-spinner").hide();
            $(
                "#login-error-message"
            ).html(`<div class="alert alert-danger alert-dismissible fade show mt-5" role="alert">
        <h4 class="alert-heading">Login error</h4>
          <p>The email or password you entered is incorrect.</p>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        </div>`);
        }, 2000);
    });

    $("#refresh-captcha").on("click", function () {
        $.ajax({
            type: "GET",
            url: "refresh-captcha",
            success: function (data) {
                $(".captcha span").html(data.captcha);
            },
        });
    });

    if (
        window.location.pathname ==
        "/presentation-area/user-stories/one/create-profile"
    ) {
        console.log("hello");
        var updates = [
            "Analysing your profile...",
            "Finding profile matches...",
            "Adding the final touches...",
        ];
        let currentIndex = 1;
        let t = setInterval(() => {
            $("#status").text(updates[currentIndex]);
            if (currentIndex >= updates.length - 1) {
                clearInterval(t);

                setTimeout(function () {
                    $("#link").fadeIn();
                    $(".lds-roller").fadeOut();
                    $("#status").text("Done!");
                }, 3000);
                return;
            }
            currentIndex++;
        }, 3000);
        $("#status").text(updates[0]);
    }

    if (
        window.location.pathname.includes("/presentation-area/user-stories/two")
    ) {
        $(".img-thumbnail").on("click", function () {
            $("#preview-image").attr("src", $(this).attr("src"));
            $("#preview-name").text($(this).data("name"));
            $("#preview-description").text($(this).data("description"));
            $("#staticBackdrop").modal("show");
        });
    }
});
